// app/javascript/controllers/pricing_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["price", "monthly", "yearly", "frequency", "coupon"];

  connect() {
    console.log('Pricing controller connected');
    this.selectedOption = "yearly"; // Default to yearly pricing
    this.togglePricing();
  }

  toggle(event) {
    // Update the selectedOption based on the data-pricing-target of the clicked label
    this.selectedOption = event.currentTarget.dataset.pricingTarget;
    this.togglePricing();
  }

  togglePricing() {
    const isYearly = this.selectedOption === "yearly";

    // Update prices based on the selected option
    this.priceTargets.forEach((priceTarget) => {
      const yearlyPrice = priceTarget.dataset.yearly;
      const monthlyPrice = priceTarget.dataset.monthly;
      priceTarget.textContent = isYearly ? yearlyPrice : monthlyPrice;
    });

    // Update frequency text based on the selected option
    this.frequencyTargets.forEach((frequencyTarget) => {
      frequencyTarget.textContent = isYearly ? "Billed Yearly" : "Billed Monthly";
    });

    // Update the active class on the labels to reflect the selected option
    this.yearlyTarget.classList.toggle("active", isYearly);
    this.monthlyTarget.classList.toggle("active", !isYearly);

    // Update coupon code based on the selected option
    const couponCode = isYearly ? "WELCOME-YEARLY50" : "WELCOME-MONTHLY50";
    this.couponTargets.forEach((couponTarget) => {
      couponTarget.textContent = couponCode;
    });
  }
}
